import React, { type FC } from "react";
import { Link } from "gatsby";
import { type GalleryProps } from "../types";
const GalleryWrapper: FC<GalleryProps> = ({
  elements,
  ItemGallery,
  isExternalLink,
  singleElement,
}) => {
  return (
    <div className="gallery-wrapper-p">
      {singleElement != null ? (
        singleElement.album.map((el, inx) => {
          return <ItemGallery key={`sng-${inx}`} {...singleElement} />;
        })
      ) : (
        <></>
      )}
      {elements.length > 0 &&
        elements.map((e) => {
          if (isExternalLink) {
            return (
              <Link key={e.id} to={`/media/gallery/${e.id}`}>
                <ItemGallery key={`${e.id}-g-item`} {...e} />
              </Link>
            );
          }
          return <ItemGallery key={`${e.id}-g-item`} {...e} />;
        })}
    </div>
  );
};

export default GalleryWrapper;
