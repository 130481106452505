import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React from "react";
import { useLocation } from "@reach/router";
import Htvideo from "@/components/Media/Htvideo";
import GallerySection from "@/components/Media/GallerySection";
import Partners from "@/components/Home/Partners";
import "@/components/Media/media.css";
const MediaP = () => {
  return (
    <Layout>
      <SEO title="Media" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Media" pathName="Media" imgUrl="/images/media-bg.png" />
      <Htvideo />
      <GallerySection />
      <Partners />
    </Layout>
  );
};

export default MediaP;
