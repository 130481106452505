/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from "react";
import Image from "../../Image";

interface Props {
  captionImagenName?: string;
  label?: string;
  containerClass?: string;
  imagenClass?: string;
  hasLabel?: boolean;
}
const GalleryItem = ({
  captionImagenName = "ss2.png",
  label = "name",
  containerClass = "item-main-g",
  imagenClass = "img-it-main",
  hasLabel = true,
}: Props) => {
  return (
    <div className={containerClass}>
      <Image name={captionImagenName} classes={imagenClass} />
      {hasLabel ? <span>{label}</span> : <></>}
    </div>
  );
};

export default GalleryItem;
