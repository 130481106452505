import React from "react";

import { VideoSection } from "../Home2/HowToVideos";
const Htvideo = () => {
  return (
    <div className="container p-values-top-bottom">
      <div
        data-name="Videos"
        className="rts-title-area center-custom text-center"
      >
        <p className="pre-title more-ab-sect-title subtitlemedia ">Media</p>
        <h2 className="title titlemedia">How-to videos</h2>
      </div>
      <VideoSection />
    </div>
  );
};

export default Htvideo;
