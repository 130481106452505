/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { type Props } from "./types";
import GalleryItem from "./galleryParts/GalleryItem";
import GalleryWrapper from "./galleryParts/GalleryWrapper";

const GallerySection = () => {
  const data: Props = useStaticQuery(graphql`
    query {
      allGalleryJson {
        nodes {
          id
          label
          captionImagenName
          album {
            name
            width
            height
          }
        }
      }
    }
  `);
  return (
    <div className="container p-values-top-bottom">
      <div
        data-name="Image"
        className="rts-title-area center-custom text-center"
      >
        <p className="pre-title more-ab-sect-title subtitlemedia ">Media</p>
        <h2 className="title titlemedia">Image Gallery</h2>
      </div>
      <GalleryWrapper
        elements={data ? data.allGalleryJson.nodes : []}
        ItemGallery={GalleryItem}
        isExternalLink={true}
      />
    </div>
  );
};

export default GallerySection;
